function recipeSteps($recipeSteps){
	this.ui = {
		idlock : false,
		animLock : false,
		animateTime : 1500,
		recipeSteps_current: 0,
		recipeSteps_toAnimate : [],
		is_animating : false,
		$recipeSteps : $recipeSteps
	};

	this.ui.$recipeSteps = $recipeSteps;

	this.sectionIdToNavigationLink = {};

	this.init = function() {
		this.bindUI();
		this.bindEvents();

		this.onScroll();
	}
	
	this.bindUI = function() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);

		this.ui.$recipeSteps__line = this.ui.$recipeSteps.find(".js-recipeSteps__line");
		this.ui.$recipeStep = this.ui.$recipeSteps.find(".js-recipeStep");
		this.ui.$recipeStep.addClass("js-on");

		for (let i = 0; i < this.ui.$recipeStep.length; i++) {
			let $step = this.ui.$recipeStep.eq(i);
			this.ui.recipeSteps_toAnimate[i] = {
				step : $step,
				status: "waiting"
			};
		}

		window.reset_recipeSteps = ()=>{
			this.ui.$recipeStep.removeClass("active");
			this.ui.$recipeSteps__line.css({"height" : 0});
		};
	}

	this.bindEvents = function() {
		// this.ui.$window.on('scroll', this.onScroll.bind(this));
	}

	this.animateStep = function() {
		// var animateTime = 250;
		var animateTime = 0;
		if(this.ui.recipeSteps_toAnimate[this.ui.recipeSteps_current] !== undefined && this.ui.recipeSteps_toAnimate[this.ui.recipeSteps_current].status === "to_animate"){
			this.ui.is_animating = true;
			this.ui.recipeSteps_toAnimate[this.ui.recipeSteps_current].step;
			if(this.ui.$recipeSteps__line.height() <  this.ui.recipeSteps_toAnimate[this.ui.recipeSteps_current].step.position().top){
				this.ui.$recipeSteps__line.animate(
					{
						"height": this.ui.recipeSteps_toAnimate[this.ui.recipeSteps_current].step.position().top
					},
					animateTime
				);
			}
			setTimeout( ()=>{
				this.ui.recipeSteps_toAnimate[this.ui.recipeSteps_current].step.addClass("active");
				this.ui.recipeSteps_toAnimate[this.ui.recipeSteps_current].status = "done";
				
				setTimeout( ()=>{
					this.ui.recipeSteps_current = this.ui.recipeSteps_current+1;
					this.animateStep();
				},animateTime);
			},animateTime);
		}else{
			this.ui.is_animating = false
		}
	}

	this.onScroll = function() {
		// if(!this.ui.animLock){
			if(!this.ui.idlock){
				clearTimeout(this.ui.idlock)
			}
			this.ui.idlock = setTimeout(
				()=>{
					this.ui.animLock = true;
					let	$window = $(window)
					,	scrollTop = $window.scrollTop()
					,	scrollCustomPositionON = scrollTop + $window.height()*0.6
					,	scrollCustomPositionOFF = scrollTop + $window.height();

					let $firstStep = this.ui.$recipeStep.eq(0);
					// if ($firstStep.length && scrollCustomPositionON >= $firstStep.offset().top) {
					if (true) {
						for (let i = 0; i < this.ui.$recipeStep.length; i++) {
							let $step = this.ui.$recipeStep.eq(i);
							// if (scrollCustomPositionON >= $step.offset().top) {
								if (
									!$step.hasClass("active")
									&& !$step.hasClass("animationIncomming")
									// && (
									// 	$step.closest('.js-prep-tab-content').length == 0 || $step.closest('.js-prep-tab-content').hasClass('swiper-slide-active') 
									// )
								) {
									$step.addClass("animationIncomming");
									this.ui.recipeSteps_toAnimate[i].status = "to_animate";
								}
							// }
						}
					}
					if (this.ui.recipeSteps_toAnimate.length === 0) {
						this.ui.animLock = false;
					} else {
						for (let i = 0; i < this.ui.recipeSteps_toAnimate.length; i++) {
							if (this.ui.recipeSteps_toAnimate[i].status === "to_animate" && this.ui.is_animating !== true) {
								this.animateStep();
								break;
							}
						}
					}
				},
				100
			);
		// }
	}
}

module.exports = recipeSteps;
