const header = {
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function bindUI() {
		this.ui.$body = $("body");
		this.ui.$filter = $(".js-rcpFilters");
		this.ui.$toggleFilter = $(".js-rcpTriggerOpenFilters");
		this.ui.$closeFilter = $(".js-close-rcpFilters");
		this.ui.$recipeType = $(".js-rcpRecipeTypeTriggerChange");
		this.ui.$recipeCapsule = $(".js-rcprecipeCapsuleTriggerChange");

		this.ui.$navMobileTriggerOpen = $(".js-RCPHeaderNavMobile-triggerOpen");
		this.ui.$navMobileTriggerClose = $(".js-RCPHeaderNavMobile-close");
		this.ui.$navMobile = $(".js-RCPHeaderNavMobile");
	},

	bindEvents: function bindEvents() {
		this.ui.$filter.on('click', this.preventFilter.bind(this));
		this.ui.$toggleFilter.on('click', this.toggleFilter.bind(this));
		this.ui.$closeFilter.on('click', this.closeFilter.bind(this));
		this.ui.$body.on('click', this.closeFilter.bind(this));

		this.ui.$navMobileTriggerOpen.on('click', this.toggleNavMobile.bind(this));
		this.ui.$body.on('click', this.closeNavMobile.bind(this));
		this.ui.$navMobileTriggerClose.on('click', this.closeNavMobile.bind(this));
		this.ui.$navMobile.on('click', this.preventNavMobile.bind(this));
		
		this.ui.$recipeType.on('change', this.recipeTypeChange.bind(this));
		this.ui.$recipeType.change();
		this.ui.$recipeCapsule.on('change', this.recipeCapsuleChange.bind(this));
		this.ui.$recipeCapsule.change();
	},

	preventNavMobile: function(e) {
		e.stopPropagation();
	},

	toggleNavMobile: function(e) {
		e.stopPropagation();
		if(!this.ui.$navMobileTriggerOpen.hasClass("active")){
			this.openNavMobile();
			this.closeFilter();
		}else{
			this.closeNavMobile();
		}
	},

	openNavMobile: function(e) {
		this.closeFilter();
		this.ui.$navMobileTriggerOpen.addClass("active");
		this.ui.$navMobile.removeClass("js-hide");
		this.ui.$navMobileTriggerClose.removeClass("js-hide");
	},

	closeNavMobile: function(e) {
		this.ui.$navMobileTriggerOpen.removeClass("active");
		this.ui.$navMobile.addClass("js-hide");
		this.ui.$navMobileTriggerClose.addClass("js-hide");
	},

	preventFilter: function(e) {
		e.stopPropagation();
	},

	toggleFilter: function(e) {
		e.stopPropagation();
		this.closeNavMobile();
		if(!this.ui.$toggleFilter.hasClass("active")){
			this.openFilter();
		}else{
			this.closeFilter();
		}
	},

	openFilter: function(e) {
		this.ui.$toggleFilter.addClass("active");
		var maxHeight = $(window).height()-($(".rcpHeader").height()+($("#header").height()||0));
		this.ui.$filter.css({"max-height": maxHeight});
		this.ui.$filter.slideDown();
		$("html").addClass("js-noScroll");
		this.ui.$closeFilter.addClass("open");
	},
	closeFilter: function(e) {
		var self = this
		self.ui.$closeFilter.addClass("closing");
		self.ui.$closeFilter.removeClass("open");
		setTimeout(function(){
			self.ui.$closeFilter.removeClass("closing");
			self.ui.$toggleFilter.removeClass("active");
			self.ui.$filter.slideUp();
			$("html").removeClass("js-noScroll");
		},250);
	},

	recipeTypeChange: function(e) {
		let self = this;
		let $this = $(e.target);
		let val = $this.val();
		if($this.prop("checked")){
			$(".js-rcpFilters__RecipeType").addClass("js-hide");
			$(".js-rcpFilters__RecipeType-"+val).removeClass("js-hide");
		}
	},

	recipeCapsuleChange: function(e) {
		let self = this;
		let $this = $(e.target);
		let val = $this.val();
		if($this.prop("checked")){
			// $(".js-capsuleType").addClass("js-hide");
			$(".js-capsuleType-"+val).removeClass("js-hide");
		}else{
			$(".js-capsuleType-"+val).addClass("js-hide");			
		}
	},

};

module.exports = header;
