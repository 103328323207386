import Swiper from "../../../gulp/node_modules/swiper";

const carouselCreations = {
	carousel:false,
	init: function() {
		// https://swiperjs.com/api/
		this.carouselInit();

		$(window).on('resize', this.carouselInit.bind(this));
	},
	carouselInit: function() {
		if(window.matchMedia("(max-width: 1000px)").matches){
			if(!this.carousel){
				this.carousel = new Swiper(".js-carouselCreations", {
					// grabCursor: true,
					centeredSlides: true,
					slidesPerView: "auto",
					// slidesPerView: 3,
					slidesPerGroup: 1,
					loop: true,
					// speed:500,
					loopedSlides: 2
				});
			}
		}else{
			if(this.carousel){
				this.carousel.destroy(true, true);
				this.carousel = false;
			}
		}
	}

};

module.exports = carouselCreations;