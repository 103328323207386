// this use jquery ui tooltip system

const recipeSearch = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();

		this.initSearchPage();
	},

	bindUI: function() {
		this.ui.$recipeSearchList = $('.js-recipe-search-list');
		this.ui.$recipeSearchFilters = $('.js-recipe-search-filters');
		this.ui.$recipeCard = this.ui.$recipeSearchList.find(".js-recipeCard")
		this.ui.$noResults = this.ui.$recipeSearchList.find(".js-no-results")
	},
	
	bindEvents: function() {
		$("html").on("click", ".js-recipe-search-filter-remove", this.removeFilterBtn.bind(this));

		window.onpopstate = this.onpopstate.bind(this);
	},

	onpopstate: function(e){
		this.initSearchPage();
	},
	
	setUrlParams: function($cardLink,urlParams) {
		// console.log("setUrlParams");
		// console.log($cardLink);
		// console.log($cardLink.attr("href"));
		// console.log($cardLink.attr("href")+urlParams);
		$cardLink.attr("href",$cardLink.attr("href")+urlParams);
		
	},
	showIfFiltered: function($card) {
		$card.closest(".js-recipeGrid__item").addClass("js-hide")

		let isOk = 0
		,	searchParamsLength = 0;

		let searchParamsNameCounterTab = [];
		for (let paramIndex = 0; paramIndex < this.ui.searchParams.length; paramIndex++) {
			let filterName = this.ui.searchParams[paramIndex]["filterName"];
			let filterValue = this.ui.searchParams[paramIndex]["filterValue"];
			let cardDatas = $card.attr("data-"+filterName)
			if(cardDatas){
				cardDatas = cardDatas.split(",");
				for (var i = 0; i < cardDatas.length; i++) {
					if(cardDatas[i] == filterValue){
						if(filterName == "allergens"){
							if(typeof searchParamsNameCounterTab[filterName] == undefined || !searchParamsNameCounterTab[filterName]){
								isOk--;
							}
						}else{
							if(typeof searchParamsNameCounterTab[filterName] == undefined || !searchParamsNameCounterTab[filterName]){
								isOk++;
								searchParamsNameCounterTab[filterName] = true;
							}
						}
					}
				}
			}else{
				if(cardDatas == filterValue){
					if(filterName == "allergens"){
						
					}
					if(typeof searchParamsNameCounterTab[filterName] == undefined || !searchParamsNameCounterTab[filterName]){
						isOk++;
						searchParamsNameCounterTab[filterName] = true;
					}
				}
			}
			if(!searchParamsNameCounterTab[filterName] && filterName != "allergens"){
				searchParamsNameCounterTab[filterName] = false;
			}
		}
		
		// // doesn't work on nespresso's prod for unknown reason
		// let searchParamsNameCounter = 0;
		// for (let index in searchParamsNameCounterTab) {
		// 	searchParamsNameCounter++;
		// }

		let searchParamsNameCounter = Object.keys(searchParamsNameCounterTab).length;

		if(isOk >= searchParamsNameCounter){
			$card.closest(".js-recipeGrid__item").removeClass("js-hide")
		}
	},

	removeFilterBtn: function(e) {
		var $this = $(e.target)
		,	dataIndex_to_remove = $this.attr("data-index")
		,	dataValue_to_remove = $this.attr("data-value");
		$this.closest(".js-recipe-search-filter").remove();
		var $filters = $(".js-recipe-search-filter").not(".js-recipe-search-filter-tpl");
		
		var newparams = []
		for (var i = 0; i < $filters.length; i++) {
			var dataIndex = $filters.eq(i).attr("data-index");
			var dataValue = $filters.eq(i).attr("data-value");

			var searchParam_item = {
				"filterName": dataIndex,
				"filterValue": dataValue
			};
			newparams.push(searchParam_item);
		}
		var newparams_search = "?";
		var isfirst = true;
		for (var i = 0; i < newparams.length; i++) {
			if(isfirst){
				isfirst = false;
			}else{
				newparams_search += "&";
			}
			newparams_search += newparams[i]["filterName"]+"="+newparams[i]["filterValue"];
		}
		if(window.history){
			window.history.pushState({searchParams:newparams_search},"", window.location.origin+window.location.pathname+newparams_search);
			this.initSearchPage();
		}else{
			window.location.href = window.location.origin+window.location.pathname+newparams_search;
		}
	},

	addFilterBtn: function(index,value) {
		var $filter = $(".js-recipe-search-filter-tpl").clone()
		$filter.removeClass("js-recipe-search-filter-tpl").removeClass("js-hide")
			.attr("data-index", index)
			.attr("data-value", value);
		$filter.find(".js-recipe-search-filter-name").text(value);
		this.ui.$recipeSearchFilters.append($filter)
	},

	initSearchPage: function() {
		$(".js-recipe-search-filter").not(".js-recipe-search-filter-tpl").remove();
		this.ui.searchParams = [];
		this.ui.allergens = [];
		var search = window.location.search
		if(search){
			search=search.split("?");
		}
		if(search[1]){
			var searchItems = search[1].split("&");
			for (var i = 0; i < searchItems.length; i++) {
				var searchItem = searchItems[i].split("=");
				var searchItemContent = searchItem[1].split(",");
				if(searchItemContent.length > 1){
					// this.ui.searchParams[searchItem[0]] = [];
					for (var j = 0; j < searchItemContent.length; j++) {
						var searchParam_item = {
							"filterName":searchItem[0],
							"filterValue":searchItem[j]
						};
						this.ui.searchParams.push(searchParam_item);
						if(searchItem[0] == "allergens"){
							this.ui.allergens.push(searchParam_item);
						}
						this.addFilterBtn(searchItem[0],searchItemContent[j])
					}
				}else{
					var searchParam_item = {
						"filterName":searchItem[0],
						"filterValue":searchItem[1]
					};
					this.ui.searchParams.push(searchParam_item);
					if(searchItem[0] == "allergens"){
						this.ui.allergens.push(searchParam_item);
					}
					this.addFilterBtn(searchItem[0],searchItem[1]);
				}
			}
			
			var urlParams = "?";
			var isfirst = true;
			for (let paramIndex = 0; paramIndex < this.ui.allergens.length; paramIndex++) {
				let filterName = this.ui.allergens[paramIndex]["filterName"];
				let filterValue = this.ui.allergens[paramIndex]["filterValue"];

				if(isfirst){
					isfirst = false;
				}else{
					urlParams += "&";
				}
				urlParams += filterName+"="+filterValue;
			}
			for (let i = 0; i < this.ui.$recipeCard.length; i++) {
				this.showIfFiltered(this.ui.$recipeCard.eq(i));
				this.setUrlParams(this.ui.$recipeCard.eq(i).find(".js-recipeCard-link"), urlParams);
			}
			// centre si moins de 4 résultats
			if(this.ui.$recipeSearchList.find(".js-recipeGrid__item").not(".js-hide").length < 4){
				this.ui.$recipeSearchList.find(".js-recipeGrid__content").addClass("recipeGrid__content--centerItems")
			}else{
				this.ui.$recipeSearchList.find(".js-recipeGrid__content").removeClass("recipeGrid__content--centerItems")
			}
			// si pas de résultats, msg d'erreur
			if(this.ui.$recipeSearchList.find(".js-recipeGrid__item").not(".js-hide").length === 0){
				this.ui.$noResults.removeClass("js-hide");
			}else{
				this.ui.$noResults.addClass("js-hide");
			}
		}else{
			this.ui.$recipeCard.attr("href", this.ui.$recipeCard.attr("data-href"))
			this.ui.$recipeCard.closest(".js-recipeGrid__item").removeClass("js-hide");
			this.ui.$recipeCard.closest(".js-recipeGrid__item").removeClass("js-hide");
			this.ui.$noResults.addClass("js-hide");
		}
	}
};

module.exports = recipeSearch;