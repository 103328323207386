import Swiper from "../../../gulp/node_modules/swiper";

const recipePreparation = {
	ui: {},

	sliderSpeed: 300,

	dropdownSpeed: 200,

	init: function() {
		this.bindUI();
		this.bindEvents();

		this.initSlider();
	},

	bindUI: function() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);
		this.ui.$body = $('body');

		this.ui.$tabNav = $(".js-tab-nav");
		this.ui.$tabButton = $(".js-prep-tab-button");
		this.ui.$tabContent = $(".js-prep-tab-content");
		this.ui.$goToSlide = $(".js-prep-goto-slide");

		this.ui.$navDropdownButton = $(".js-prep-tab-dropdown");
		this.ui.$navDropdownText = $(".js-prep-tab-dropdown-text");
	},

	bindEvents: function() {
		this.ui.$tabButton.on('click', this.switchTab.bind(this));
		this.ui.$goToSlide.on('click', this.goToSlide.bind(this));

		this.ui.$navDropdownButton.on('click', this.toggleNavMobile.bind(this));
		this.ui.$body.on('click', this.closeNavMobile.bind(this));

		this.ui.$window.on('resize', this.resetTabNav.bind(this));
	},

	initSlider: function() {
		if (this.ui.$tabContent.length > 1) {
			this.prepSlider = new Swiper('.js-prep-list', {
				slidesPerView: 1,
				speed: this.sliderSpeed,
				autoHeight: true,
				allowTouchMove: false
			});
		}
	},

	resetTabNav: function(e) {
		let tabNavAttr = this.ui.$tabNav.attr('style');
		if (typeof tabNavAttr !== typeof undefined && tabNavAttr !== false) {
			if(window.matchMedia("(min-width: 768px)").matches){
				this.ui.$navDropdownButton.removeClass('is-active');
				this.ui.$tabNav.slideUp(this.dropdownSpeed).removeAttr('style');
			}
		}
	},

	toggleNavMobile: function(e) {
		e.stopPropagation();

		let $this = this.ui.$navDropdownButton;
		if ($this.hasClass('is-active')) {
			$this.removeClass('is-active');
			this.ui.$tabNav.slideUp(this.dropdownSpeed);
		} else {
			$this.addClass('is-active');
			this.ui.$tabNav.slideDown(this.dropdownSpeed);
		}
	},

    closeNavMobile: function(e) {
		if (this.ui.$navDropdownButton.hasClass('is-active')) {
			this.ui.$navDropdownButton.removeClass('is-active');
			this.ui.$tabNav.slideUp(this.dropdownSpeed);
		}
	},

	goToSlide: function(e) {
		let targetSlide = $(e.target).attr('data-target');
		$('.js-prep-tab-button-'+targetSlide).click();
		var self = this
		setTimeout(function(){
			self.scrollToTabNav();
		},500)
	},

	scrollToTabNav: function() {
		$('html, body').animate({
			scrollTop: $(".recipePreparation__nav").offset().top
		}, 300);
	},

	switchTab: function(e) {
		let $nextTabButton = $(e.target);
		let $currentTabButton = $(".js-prep-tab-button.is-active");
		let $currentTabBar = $currentTabButton.find(".js-prep-tab-button-bar");
		let currentTab = parseInt($currentTabButton.attr('data-target'));

		if (!$nextTabButton.hasClass('is-active')) {
			let nextTab = parseInt($nextTabButton.attr('data-target'));
			let $nextTabBar = $nextTabButton.find(".js-prep-tab-button-bar");
			let currentGoTo;
			let nextFrom;

			this.ui.$navDropdownText.text($nextTabButton.text());

			/** ANIMATE THE BUTTON BAR */
			if (currentTab < nextTab) {
				// next tab is on the right
				currentGoTo = '100%';
				nextFrom = '-100%';
			} else {
				// next tab is on the left
				currentGoTo = '-100%';
				nextFrom = '100%';
			}

			this.ui.$tabContent.find(".recipeNeeds *, .recipeSteps *, .recipePreparation__footer *").css({"opacity":0});
			var self = this;
			// Animate current bar
			$currentTabBar.animate({
				left: currentGoTo
			}, 200, function() {
				$currentTabBar.hide();
				$currentTabButton.removeClass('is-active');
			});

			// Animate the next bar
			$nextTabBar.css('left', nextFrom).show();
			$nextTabButton.addClass('is-active');
			$nextTabBar.animate({
				left: '0%'
			}, 200,function(){
				self.ui.$tabContent.find(".recipeNeeds *, .recipeSteps *, .recipePreparation__footer *").css({"opacity":1});
			});
			/** ANIMATE THE BUTTON BAR */

			// Slide to the wanted tab
			this.prepSlider.slideTo(nextTab-1, this.sliderSpeed, true);
		}
	}
}

module.exports = recipePreparation;
