import Swiper from "../../../gulp/node_modules/swiper";

const carousel3D = {
	
	carousel:false,
	carouselMobile:false,

	init: function(js_carousel3D_class) {
		// https://swiperjs.com/api/
		this.carouselInit(js_carousel3D_class);

		$(window).on('resize', this.carouselInit.bind(this, js_carousel3D_class));
	},
	carouselInit: function(js_carousel3D_class) {
		if(window.matchMedia("(max-width: 1000px)").matches){
			if(!this.carouselMobile){
				if(this.carousel){
					this.carousel.destroy(true, true);
					this.carousel = false;
				}
				this.carouselMobile = new Swiper(js_carousel3D_class, {
					grabCursor: true,
					centeredSlides: true,
					slidesPerView: 'auto',
					navigation: {
						nextEl: '.js-swiper-button-next',
						prevEl: '.js-swiper-button-prev',
					},
					loop: true
				});
			}
		}else{
			if(!this.carousel){
				if(this.carouselMobile){
					this.carouselMobile.destroy(true, true);
					this.carouselMobile = false;
				}
				// https://swiperjs.com/api/
				this.carousel = new Swiper(js_carousel3D_class, {
					effect: "coverflow",
					grabCursor: true,
					centeredSlides: true,
					slidesPerView: 'auto',
					navigation: {
						nextEl: '.js-swiper-button-next',
						prevEl: '.js-swiper-button-prev',
					},
					loop: true,
					// freeMode: true,
					// freeModeSticky: true,
					// freeModeMomentum: true,
					// freeModeMomentumRatio: 0.5,
					slideToClickedSlide: true,
					speed:1000,
					coverflowEffect: {
						rotate: 0,
						// stretch: 100,
						// depth: 310,
						stretch: 80,
						depth: 150,
						modifier: 1,
						slideShadows: false
					},
					// pagination: {
					// 	el: ".swiper-pagination"
					// }
				});
			}
		}
	}
};

module.exports = carousel3D;