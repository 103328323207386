import Swiper from "../../../gulp/node_modules/swiper";

const carouselMultipleItem = {
	
	init: function() {
		// https://swiperjs.com/api/
		var swiper = new Swiper(".js-carouselMultipleItem", {
			grabCursor: true,
			centeredSlides: false,
			slidesPerView: "auto",
			// slidesPerView: 1,
			// slidesPerGroup: 1,
			// spaceBetween:8,
			loop: true,
			speed:500,
			navigation: {
				nextEl: '.js-swiper-button-next',
				prevEl: '.js-swiper-button-prev',
			},
			pagination: {
				el: ".js-carouselMultipleItem-pagination",
				type: 'custom',
				renderCustom: function (swiper, current, total) {

					if (window.matchMedia("(min-width: 1200px)").matches) {
						return '<span>'+current*4+'</span>' +
							' — ' +
							'<span>'+total*4+'</span>';
					}else{

						if (window.matchMedia("(min-width: 820px)").matches) {

							return '<span>'+current*3+'</span>' +
								' — ' +
								'<span>'+total*3+'</span>';
						}else{

							if (window.matchMedia("(min-width: 561)").matches) {

								return '<span>'+current*2+'</span>' +
									' — ' +
									'<span>'+total*2+'</span>';
							}else{

								return '<span>'+current+'</span>' +
									' — ' +
									'<span>'+total+'</span>';
							}
						}
					}
					return '<span>'+current+'</span>' +
						' — ' +
						'<span>'+total+'</span>';
				}
			},
			// breakpoints: {
			// 	// when window width is >= 1200
			// 	1201: {
			// 		slidesPerView: 4,
			// 		slidesPerGroup: 4
			// 	},
			// 	// when window width is >= 820
			// 	821: {
			// 		slidesPerView: 3,
			// 		slidesPerGroup: 3
			// 	},
			// 	// when window width is >= 560
			// 	561: {
			// 		slidesPerView: 2,
			// 		slidesPerGroup: 2
			// 	}
			// }
		});
	},
};

module.exports = carouselMultipleItem;