import Swiper from "../../../gulp/node_modules/swiper";

const carouselBanner = {
	
	init: function() {
		// https://swiperjs.com/api/
		var swiper = new Swiper(".js-carouselBanner", {
			autoplay: {
				delay: 4000,
			},
			grabCursor: false,
			centeredSlides: false,
			slidesPerView: 1,
			loop: true,
			speed:800,
			navigation: {
				nextEl: '.js-swiper-button-next',
				prevEl: '.js-swiper-button-prev',
			},
			pagination: {
				el: ".js-carouselBanner-pagination",
				clickable: true
			},
		});
	},
};

module.exports = carouselBanner;