var popin = {
    init: function init() {
        var self = this;
        $(".js-RCPPopin").each(function(){
            self.initPopinHTML($(this));
        });
        self.positionPopin();
        self.bindEvents(self);
        self.onresize(self);
    },
    initPopinHTML: function($popin){
        $popin
            .css({"display":"none"})
            .wrap($("<div class='RCPPopin__overlay js-RCPPopin__overlay' style='display:none;'></div>"));
    },
    bindEvents: function(self){
        $("body").on("click", ".js-showRCPPopin", function(e){
            console.log("js-showRCPPopin");
            e.preventDefault();
            var $this = $(this)
            self.openPopin(self, $this);
        });

        $("body").delegate(".js-RCPPopin","click", function(e){
            e.stopPropagation();
        });

        window.closePopin = function(){
            $(".js-RCPPopin").hide().closest('.js-RCPPopin__overlay').hide();
            $("html,body").removeClass("js-noScroll");
        }

        $("body").delegate(".js-RCPPopin__close, .js-RCPPopin__overlay", "click", function(){
            window.closePopin();
        });
    },
    openPopin: function(self, $this){
        var $target = $("#"+$this.attr("data-target"))
        ,   onInit = $target.attr("data-onInit");
        
        $("html,body").addClass("js-noScroll");

        $this.blur();
        $target.show()
               .closest(".js-RCPPopin__overlay").addClass("RCPPopin--beforeAnimate").show();
        setTimeout(function(){
            $target.closest(".js-RCPPopin__overlay").addClass("RCPPopin--animate");
            if(onInit){
                $(window).trigger(onInit);
            }
            setTimeout(function(){
                $target.closest(".js-RCPPopin__overlay").removeClass("RCPPopin--beforeAnimate").removeClass("RCPPopin--animate");
            }, 1000);
        }, 5);
        self.positionPopin($target);
    },
    positionPopin: function($target){
        if(typeof $target === "undefined" || typeof $target === "object"){
            var $target = $(".js-RCPPopin");
        }
        $target.each(function(){
            var $target = $(this)
            ,   wh = $(window).height()
            ,   ph = $target.outerHeight();
            if( wh > (ph+20) ){
                $target.css({"margin-top" : (wh-ph)/2})
            }else{
                $target.css({"margin-top" : 0})
            }
        });
    },
    onresize: function(self){
        if(window.addEventListener){
            window.addEventListener('resize', function(){
                self.positionPopin();
            }, false);
        }else{
            window.attachEvent ('onresize', function(){
                self.positionPopin();
            });
        }
    }
};

module.exports = popin;